.box {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.label {
  color: var(--color-text-primary);
}

.input {
  width: 100%;
  padding: 15px 15px;
  border: 1px solid var(--color-text-primary);
  border-color: transparent;
  border-radius: 5px;
  background: #031852;
  color: var(--color-text-primary);

  &::placeholder {
    color: gainsboro;
  }

  &:focus {
    border-color: var(--color-primary);
  }
}
