
.overlay{
    width: 100%;
    position: fixed;
    top:0;
    z-index: 10000;
    left:0;
    height: 100%;
    background-color: rgba(17, 22, 19, 0.88);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal{
    background-color: rgba(3, 33, 118, 1);
    width: 100%;
    height: auto;
    position: relative;
    border-radius: 14px;
    margin-top: 30px ;
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    gap:20px;
    }


.players{
    display:flex;
    width: 100%;
    justify-content: space-between;
    color: rgba(190, 193, 190, 1);
}

.playersBlock{
    width: 100%;
    background-color: rgba(3, 24, 82, 1);
    border-radius: 15px;
    padding: 20px;
    line-height: 2;
    text-align: center;
}

.button{
    background-color: rgba(0, 128, 0, 1);
    width: 30%;
    height: 40px;
    padding: 0;
    margin-top: 20px;
}


.container{
    display: flex;
    gap:20px;
    width: 100%;
    justify-content: center;

}

.containerPrice {
  width: 100%;
    
  }
  
  .inputWrapper {
    display: flex; 
    gap: 20px; 
    flex-wrap: wrap; 
  }
  
  .inputGroup {
    flex: 1; 
    min-width: 0; 
    display: flex;
    flex-direction: column;
    gap: 5px; 
  }
  
  label {
    color: var(--color-text-primary);
  }
  
  input {
    width: 100%;
    padding: 15px 15px;
    border: 1px solid var(--color-text-primary);
    border-color: transparent;
    border-radius: 5px;
    background: #031852;
    color: var(--color-text-primary);
  
    &::placeholder {
      color: gainsboro;
    }
  
    &:focus {
      border-color: var(--color-primary);
    }
  }