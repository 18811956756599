:root {
  --color-dark: #080C0A;
  --color-bg: #041440;
  --color-blue: #031852;
  --color-primary: #405742;
  --color-danger: #be3144;

  --color-text-primary: #f0fff0;
  --color-text-secondary: #a5cbc3;
}
// :root {
//   --color-dark: #161b21;
//   --color-bg: #1d232c;
//   --color-primary: #1976d214;
//   --color-danger: #be3144;

//   --color-text-primary: #eef0f4;
//   --color-text-secondary: #7d8fa9;
// }
