$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 65, 70, 75, 100);
$sides: (top, bottom, left, right, all);

@each $space in $spaceamounts {
  @each $side in $sides {
    @if $side == 'all' {
      .m-#{$space} {
        margin: #{$space}px;
      }

      .p-#{$space} {
        padding: #{$space}px;
      }
    } @else {
      .m#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space}px;
      }

      .p#{str-slice($side, 0, 1)}-#{$space} {
        padding-#{$side}: #{$space}px;
      }
    }
  }
}

$dirs: (row, column, all);
@each $space in $spaceamounts {
  @each $dir in $dirs {
    @if $dir == 'all' {
      .gap-#{$space} {
        gap: #{$space}px;
      }
    } @else {
      .#{str-slice($dir, 0, 1)}-gap-#{$space} {
        #{$dir}-gap: #{$space}px;
      }
    }
  }
}

.flex {
  display: flex;
}

.f-row {
  flex-direction: row;
}

.f-col {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.jst-center {
  justify-content: center;
}

.jst-start {
  justify-content: flex-start;
}

.jst-end {
  justify-content: flex-end;
}

.jst-between {
  justify-content: space-between;
}

.wrap {
  flex-wrap: wrap;
}

.w-max {
  width: 100%;
}

.h-max {
  height: 100%;
}

.w-100 {
  width: 100vw;
}

.h-100 {
  height: 100vh;
}

.cp {
  cursor: pointer;
}
