.fc .fc-timegrid-slot-label{
    z-index: 1;
    position: relative;
}
  
.fc-scroller{
    overflow: hidden !important;
}
  
.fc-timegrid-event .fc-event-time{
    color: rgba(165, 203, 195, 1) !important;
    margin-bottom: 5px !important;
}

.fc-v-event{
    padding: 10px;
    margin-top: 1px !important;
    border: none !important;
    background-color: rgba(42, 89, 210, 1) !important;
    border-left: 7px solid saddlebrown !important;
    box-shadow: none !important;
    font-size: 16px !important;
    z-index: 10000000;
    position: absolute !important;
    left: 130px !important;
    cursor: pointer !important;
}

.fc-event-selected::after, .fc-event:focus::after{
    position:  relative !important;
}


.fc .fc-scrollgrid-section > *{
    border: none !important;
}

.fc-timegrid-axis{
    display: none !important;
}

.fc-daygrid-dot-event .fc-event-title{
    color: white !important;
    margin-bottom: 20px !important;
    font-weight: 300 !important;
    font-size: 16px !important;
}

.fc-daygrid-event-dot{
    display: none !important;
}

.fc-day-today{
    background-color: rgba(3, 33, 118, 1) !important;
}

.fc-direction-ltr .fc-daygrid-event .fc-event-time{
    color: white !important;
}

.fc .fc-toolbar.fc-header-toolbar{
    width: 200px !important;
    display: flex;
    align-self: center;
}

.fc .fc-button{
    background-color: transparent !important;
    border: none !important
}

.fc-icon-chevron-right::before,
.fc-icon-chevron-left::before{
    color: rgba(190, 193, 190, 1) !important
}

.fc .fc-button-primary:focus{
    box-shadow: none !important;
}

.fc .fc-toolbar-title{
    color: rgba(190, 193, 190, 1) !important;
    font-size: 16px !important;
    font-weight: 300 !important;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
  
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
  
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal {
    background-color: rgba(3, 24, 82, 1);
    border-radius: 5px;
}

#day .fc{
    width: 700px !important;

}

#day .fc .fc-timegrid-slot-minor,
#day .fc .fc-timegrid-slot,
#day .fc .fc-timegrid-now-indicator-container,
#day .fc .fc-daygrid-body-natural .fc-daygrid-day-events,
#day .fc .fc-daygrid-day-frame,
#day.fc{
    background-color: rgba(3, 33, 118, 1)!important;
}
  
  
#day .fc .fc-daygrid-body-natural, #day .fc-daygrid-day-events,
#day .fc .fc-daygrid-day-frame, #day .fc .fc-daygrid-day.fc-day-today,
#day .fc .fc-timegrid-axis-frame,
#day .fc .fc-col-header-cell-cushion{
    display: none !important;
}
  
  
#day .fc .fc-timegrid-divider{
    display: none !important;
}
  
#day .fc-scroller .fc-scroller-liquid-absolute{
    overflow: hidden !important;
}
  

#day .fc .fc-timegrid-slot-label-cushion{
    width: 100px;
    text-transform: uppercase;
    font-weight: 300 !important;
    color: rgba(165, 203, 195, 1);
    padding-right: 20px !important;
    display: flex;
    font-size: 16px;
    align-items: flex-end;
    z-index: 111 !important; 
    margin-top: -35px !important;
    border-right: none !important;
    position: absolute;
    left:0;
    background-color: rgba(3, 33, 118, 1) !important;
}
  
  
#day .fc .fc-scrollgrid-section-liquid > td{
    border:none !important
}
  
#day .fc-theme-standard td, .fc-theme-standard th {
    border-bottom:none  !important
}

#day .fc .fc-cell-shaded, #day .fc .fc-day-disabled{
    background-color: transparent !important;
}
  
#day .fc-theme-standard td{
    border-right: none !important;
} 

  
#day .fc-timegrid-event .fc-event-main,
#day .fc .fc-scrollgrid-liquid{
    border:none !important
}
  
#day .fc .fc-timegrid-slot-minor{
    border-bottom: 0.5px solid rgba(165, 203, 195, 1);
    border-top: none !important;
    border-left: none !important;
}
  
#day .fc .fc-timegrid-slot-lane{
    border-left: none !important;
    height: 30px !important;
}
  
#day .fc .fc-scrollgrid-liquid{
    border: none !important
}
  
#month .fc{
    margin-top: 10px !important;
    width: 100% !important;
}
      
#month .fc-theme-standard .fc-scrollgrid{
    border: none !important
}

#month .fc .fc-daygrid-day-number{
    color: rgba(175, 185, 192, 1) !important
}  
      
#month .fc-theme-standard td{
    border: 0.5px solid rgba(114, 135, 150, 0.5)
}

#month .fc-theme-standard th{
    border: none !important;
    padding-bottom: 5px !important;
}

#month .fc-theme-standard td, .fc-theme-standard th{
    border: 0.5px solid rgba(114, 135, 150, 0.5)
}

#month .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    height: 110px !important;
    overflow: auto !important;
    padding: 0 5px !important;
}

#month .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events
  .fc-daygrid-event-dot {
    display: none !important; 
}        
  
#month .fc-scroller{
    overflow: hidden !important;
}
  
#month .fc-scroller.fc-scroller-liquid-absolute{
    overflow: auto scroll !important;
}

#month .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start{
    padding: 10px;
    border-radius: 7px !important;
    border: none !important;
    cursor:  pointer !important;
    border-left: 7px solid !important;
    box-shadow: none !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    height: 40px !important;
    align-items: flex-start !important;
    background-color: rgba(42, 89, 210, 1) !important;
    margin-bottom: 7px !important;
    display: flex !important;
    flex-direction: column !important;
}
  
#month .fc-day-today .fc-daygrid-day-number{
    color: rgba(13, 221, 213, 1) !important;
    font-weight: bold !important;
}

#week .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start{
    border-radius: 7px !important;
    padding-left: 10px !important;
    border: none !important;
    border-left: 7px solid !important;
    box-shadow: none !important;
    gap:10px !important;
    height: 95px !important;
    cursor: pointer !important;
    align-items: flex-start !important;
    border-color: rgba(165, 203, 195, 1);
    background-color: rgba(42, 89, 210, 1) !important;
    margin-bottom: 7px !important;
    display: flex !important;
    flex-direction: column !important;
    width: 9vw !important
}
  
#week .fc{
    padding-top: 30px !important;
}

#week .fc-theme-standard .fc-scrollgrid{
    border: none !important;
}

#week .fc .fc-daygrid-day-frame{
    border-top: 0.5px solid rgba(190, 193, 190, 1);
}

#week .fc-theme-standard th{
    border-color: rgba(190, 193, 190, 1) !important;
} 
 
#week .fc-scrollgrid-sync-inner{
    height: 40px !important;
    padding-top: 10px !important;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
  
#week .fc .fc-col-header-cell-cushion{
    display: flex !important;
    letter-spacing: 1px;
    font-weight: 300 !important;
    font-size: 18px !important;
    margin-top: -10px !important;
}       
  
#week .fc-direction-ltr .fc-daygrid-event .fc-event-time{
    margin-top: 15px !important;
}