*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
button,
textarea,
select {
  font: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
  height: auto;
}

input,
textarea {
  &:focus {
    outline: none;
  }
}

button {
  border: none;
  cursor: pointer;
}
